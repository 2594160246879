import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import API_CONFIG from '../config';
import apiConnection from '../objects/ApiConnection';
import { useNavigate } from 'react-router-dom';

function HelloWorld() {
  const [attributes, setAttributes] = useState([]);
  const [project, setProject] = useState('');
  const [selectedProject, setSelectedProject] = useState('');
  const [title, setTitle] = useState(''); // State for a single title for the entire JSON
  const [newKey, setNewKey] = useState('');
  const [newType, setNewType] = useState('String'); // Default type is String
  const [newSecurity, setNewSecurity] = useState('Hashed'); // Default security is Hashed
  const [newRequired, setNewRequired] = useState('Required'); // Default option is Required
  const [newAddress, setNewAddress] = useState('Value'); // Default to "Value"

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const projectId = location.state?.projectId; // Get the project ID from navigation state
    if (projectId) {
      apiConnection.fetchProjectById(projectId)
        .then(data => {
          setProject(data); // Set the project data retrieved
        })
        .catch(error => console.error(`Error fetching project with ID ${projectId}:`, error));
    }
  }, [location.state]);

  const sendDatatoBackend = () => {
    const data = {
      title, // Title of the JSON
      project: project._id, // Project under which the JSON is saved
      attributes // Array of attributes
    };

    console.log(JSON.stringify(data));

    apiConnection.postProjectDataModel(location.state?.projectId, data)
      .then(data => {
        console.log('Success:', data);
        alert('Data successfully saved!');
    
        navigate('/POManagement');
      })
      .catch(error => {
        console.error('Error:', error);
        alert(`Failed to save data: ${error.message || 'Unknown error'}`); // Show more specific error message if available
      });
  };

  const handleAddAttribute = () => {
    if (newKey.trim()) {
      setAttributes([...attributes, {
        key: newKey,
        type: newType,
        security: newSecurity,
        required: newRequired,
        address: newAddress
      }]);
      // Reset attribute input fields after adding an attribute
      setNewKey('');
      setNewType('String');
      setNewSecurity('Hashed');
      setNewRequired('Required');
      setNewAddress('Value');
    }
  };

  const handleInputChange = (setter) => (event) => {
    setter(event.target.value);
  };

  const handleDelete = (indexToDelete) => {
    setAttributes(attributes.filter((_, index) => index !== indexToDelete));
  };

  return (
    <div>
      <h1>Hello World - JSON Creator</h1>
      <div>
        <input
          type="text"
          placeholder="Title for JSON"
          value={title}
          onChange={e => setTitle(e.target.value)}
        />
      {project && <h2>Project: {project.name}</h2>}
      </div>
      <h2>Attributes</h2>
      {attributes.map((attribute, index) => (
        <div key={index}>
          <strong>{attribute.key}:</strong> {attribute.type}, {attribute.security}, {attribute.required}, {attribute.address ? attribute.address : 'Value'}
          <button onClick={() => handleDelete(index)}>Delete</button>
        </div>
      ))}
      <div>
        <input type="text" placeholder="Attribute name" value={newKey} onChange={handleInputChange(setNewKey)} />
        <select value={newType} onChange={handleInputChange(setNewType)}>
          <option value="String">String</option>
          <option value="JSON">JSON</option>
          <option value="Array">Array</option>
          <option value="Number">Number</option>
          <option value="Date">Date</option>
        </select>
        <select value={newSecurity} onChange={handleInputChange(setNewSecurity)}>
          <option value="Cleartext">Cleartext</option>
          <option value="Hashed">Hashed</option>
        </select>
        <select value={newRequired} onChange={handleInputChange(setNewRequired)}>
          <option value="Optional">Optional</option>
          <option value="Required">Required</option>
        </select>
        <select value={newAddress} onChange={handleInputChange(setNewAddress)}>
          <option value="Value">Value</option>
          <option value="Address">Address</option>
        </select>
        <button onClick={handleAddAttribute}>Add Attribute</button>
      </div>
      <button onClick={sendDatatoBackend}>Save JSON</button>
    </div>
  );
}

export default HelloWorld;
