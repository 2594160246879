import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import apiConnection from '../objects/ApiConnection'; // Replace with your actual path to the apiConnection object

function LoginWithToken() {
    const { token } = useParams(); // Extract token from the URL
    const [message, setMessage] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const handleLoginWithToken = async () => {
            try {
                const response = await apiConnection.loginWithToken(token);
                if (response.token) {
                    setMessage('Login successful! Redirecting...');
                    // Assuming you save the token in cookies
                    document.cookie = `jwtToken=${response.token}; path=/`;

                    // Redirect to the desired page after successful login
                    setTimeout(() => navigate('/pomanagement'), 2000);
                } else {
                    setMessage(response.error || 'Login failed');
                }
            } catch (error) {
                setMessage('Network error: ' + error.message);
            }
        };

        handleLoginWithToken();
    }, [token, navigate]);

    return (
        <div>
            <h1>Login With Token</h1>
            {message ? <p>{message}</p> : <p>Logging in...</p>}
        </div>
    );
}

export default LoginWithToken;
