import React, { useState, useEffect } from 'react';
import apiConnection from '../objects/ApiConnection';
import { useNavigate } from 'react-router-dom';

function RegisterClient() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [projects, setProjects] = useState([]);  // State to store projects
    const [selectedProjects, setSelectedProjects] = useState([{ id: '', name: '' }]);  // State for selected projects
    const [organizations, setOrganizations] = useState([]);  // State to store organizations
    const [selectedOrganization, setSelectedOrganization] = useState({ id: '', name: '' });  // State to store selected organization
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();

    // Fetch organizations and projects when the component mounts
    useEffect(() => {
        const fetchOrganizations = async () => {
            try {
                const response = await apiConnection.getMyOrganizations();
                setOrganizations(response.clients);  // Assuming the API returns a `clients` array
            } catch (error) {
                console.error('Error fetching organizations:', error);
                setErrorMessage('Failed to fetch organizations. Please try again.');
            }
        };

        const fetchProjects = async () => {
            try {
                const response = await apiConnection.getMyProjects();
                setProjects(response.projects);  // Assuming the API returns a `projects` array
            } catch (error) {
                console.error('Error fetching projects:', error);
                setErrorMessage('Failed to fetch projects. Please try again.');
            }
        };

        fetchOrganizations();
        fetchProjects();
    }, []);

    const handleProjectChange = (index, event) => {
        const selectedProject = projects.find(proj => proj.id === event.target.value);
        const newSelectedProjects = [...selectedProjects];
        newSelectedProjects[index] = { id: selectedProject.id, name: selectedProject.name };
        setSelectedProjects(newSelectedProjects);
    };

    const handleAddProject = () => {
        setSelectedProjects([...selectedProjects, { id: '', name: '' }]);
    };

    const handleRemoveProject = (index) => {
        const newSelectedProjects = [...selectedProjects];
        newSelectedProjects.splice(index, 1);
        setSelectedProjects(newSelectedProjects);
    };

    const handleOrganizationChange = (event) => {
        const selectedOrg = organizations.find(org => org.id === event.target.value);
        setSelectedOrganization({ id: selectedOrg.id, name: selectedOrg.name });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        const newClient = {
            name,
            email,
            organization: selectedOrganization,  // Store the selected organization with id and name
            password,
            projects: selectedProjects.filter(project => project.id !== '')  // Filter out any empty projects
        };

        try {
            const response = await apiConnection.registerUser(newClient);
            if (response.token) {
                navigate('/clients'); // Redirect to Clients screen after successful registration
            }
        } catch (error) {
            console.error('Error registering client:', error);
            setErrorMessage('Failed to register client. Please try again.');
        }
    };

    return (
        <div>
            <h1>Register New Client</h1>
            <form onSubmit={handleSubmit}>
                <div>
                    <label htmlFor="name">Name:</label>
                    <input
                        type="text"
                        id="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label htmlFor="email">Email:</label>
                    <input
                        type="email"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label htmlFor="organization">Organization:</label>
                    <select
                        id="organization"
                        value={selectedOrganization.id}
                        onChange={handleOrganizationChange}
                        required
                    >
                        <option value="" disabled>Select an organization</option>
                        {organizations.map(org => (
                            <option key={org.id} value={org.id}>
                                {org.name}
                            </option>
                        ))}
                    </select>
                </div>
                <div>
                    <label>Projects:</label>
                    {selectedProjects.map((project, index) => (
                        <div key={index} style={{ marginBottom: '10px' }}>
                            <select
                                value={project.id}
                                onChange={(e) => handleProjectChange(index, e)}
                                required
                            >
                                <option value="" disabled>Select a project</option>
                                {projects.map(proj => (
                                    <option key={proj.id} value={proj.id}>
                                        {proj.name}
                                    </option>
                                ))}
                            </select>
                            <button type="button" onClick={() => handleRemoveProject(index)}>Remove</button>
                        </div>
                    ))}
                    <button type="button" onClick={handleAddProject}>Add Project</button>
                </div>
                {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                <button type="submit">Register Client</button>
            </form>
            <button onClick={() => navigate('/clients')}>Cancel</button>
        </div>
    );
}

export default RegisterClient;
