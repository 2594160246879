const API_CONFIG = {
    paths: {
        getProjects: '/get-projects',
        postProjectDatamodel: '/postprojectdatamodel/',
        getProjectDatamodels: '/getprojectdatamodels/',
        getProjectDatamodel: '/getprojectdatamodel/',
        addClientDataModel: '/addclientdatamodel/',
        registerProductOwner: '/register_product_owner',
        login: '/login',
        addProject: '/add_project',
        getMyProjects: '/get_my_projects',
        getAllProjects: '/get_all_projects',
        getProject: '/get_project/',
        postProjectDataModel: '/postprojectdatamodel/',
        addExistingProject: '/add_existing_project',
        addOrganization: '/add_organization',
        getMyOrganizations: '/get_my_organizations',
        getAllClients: '/get_all_clients',
        addExistingClient: '/add_existing_client',
        getClient: '/get_client/',
        getClientDataModels: '/getclientdatamodel/',
        parse_data: '/parse_data/',
        run_test: '/run_test/',
        updateImportAPI: '/updateimportapi/',
        getProjectDataModels: '/getprojectdatamodels/',
        hideProjectDataModel: '/hide_project_data_model/',
        hideClientDataModel: '/hide_client_data_model/',
        addEmailServer: '/add_email_server',
        getEmailServerConfig: '/get_email_server_config/',
        addMapping: '/add_mapping',
        checkEmail: '/check_email/',
        getClients: '/get_clients',
        registerUser: '/register_user',
        deleteUser: '/delete_user/',
        getMyDataModels: '/getmydatamodels',
        getMyOrg: '/get_my_org',
        requestMagicLink: '/request_magic_link',
        loginWithToken: '/login_with_token/',
        getNewApiKey: '/get_new_api_key',
        getMyAPIKeys: '/get_my_api_keys',
        deactivateMyAPIKey: '/deactivate_my_api_key',
        getAPIKey: '/get_api_key',
        getClientDocumentation: '/get_client_documentation',
    }
};

export default API_CONFIG;
