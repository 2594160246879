import React, { useState, useEffect } from 'react';
import apiConnection from '../objects/ApiConnection';
import { useLocation } from 'react-router-dom';
import '../DocumentationStyles.css'; // Ensure custom styles are defined for documentation formatting

function Documentation() {
    const [documentation, setDocumentation] = useState({});
    const [error, setError] = useState('');
    const location = useLocation();
    const clientId = location.state?.clientId;

    useEffect(() => {
        const fetchClientDocumentation = async () => {
            try {
                const response = await apiConnection.getClientDocumentation(clientId);
                setDocumentation(response.documentation);
            } catch (error) {
                console.error('Error fetching client documentation:', error);
                setError('Failed to fetch client documentation');
            }
        };

        if (clientId) {
            fetchClientDocumentation();
        }
    }, [clientId]);

    if (error) {
        return <p style={{ color: 'red' }}>{error}</p>;
    }

    return (
        <div className="documentation-container">
            <h1 className="doc-title">Client Documentation</h1>
            {Object.keys(documentation).length === 0 ? (
                <p>Loading...</p>
            ) : (
                Object.entries(documentation).map(([modelId, modelData]) => (
                    <div key={modelId} className="doc-section">
                        <h2 className="doc-subtitle">{modelData.title}</h2>
                        <p>
                            <strong>URL:</strong> <span className="doc-url">{modelData.url}</span>
                        </p>
                        <p>
                            <strong>HTTP Method:</strong> {modelData.method}
                        </p>
                        <p>
                            <strong>Headers:</strong> {modelData.header}
                        </p>
                        
                        {/* Attributes Table */}
                        <h3>Attributes:</h3>
                        <table className="doc-attributes-table">
                            <thead>
                                <tr>
                                    <th>Attribute Name</th>
                                    <th>Type</th>
                                    <th>Required</th>
                                    <th>Description</th>
                                </tr>
                            </thead>
                            <tbody>
                                {modelData.attributes.map((attr, index) => (
                                    <tr key={index}>
                                        <td>{attr.title}</td>
                                        <td>{attr.type}</td>
                                        <td>{attr.required ? 'Yes' : 'No'}</td>
                                        <td>{`Attribute of type ${attr.type}, required: ${attr.required}`}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        {/* Example Request */}
                        <h3>Example Request:</h3>
                        <pre className="doc-json-block-left">{JSON.stringify(modelData.example, null, 2)}</pre>

                        {/* Response Structure */}
                        <h3>Response Structure:</h3>
                        <pre className="doc-json-block-left">{JSON.stringify(modelData.response, null, 2)}</pre>
                    </div>
                ))
            )}
        </div>
    );
}

export default Documentation;
