import React, { useState, useEffect } from 'react';
import apiConnection from '../objects/ApiConnection';
import { useNavigate } from 'react-router-dom';

function Clients() {
    const [clients, setClients] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchClients = async () => {
            try {
                const response = await apiConnection.getClients();
                setClients(response.clients); // Assuming `clients` is the key for the array in the response
            } catch (error) {
                console.error('Error fetching clients:', error);
                // Optionally display an error message to the user here
            }
        };

        fetchClients();
    }, []);

    const handleDelete = async (clientId) => {
        try {
            await apiConnection.deleteUser(clientId);
            setClients(clients.filter(client => client._id !== clientId));
        } catch (error) {
            console.error('Error deleting client:', error);
            // Optionally display an error message to the user here
        }
    };

    return (
        <div>
            <h1>All Clients</h1>
            <ul>
                {clients.map(client => (
                    <li key={client._id}>
                        <h2>{client.name}</h2>
                        <p>Email: {client.email}</p>
                        <p>Organization: {client.organization?.name}</p>
                        <p>Projects:</p>
                        <ul>
                            {client.projects?.map(project => (
                                <li key={project.id}>
                                    {project.name}
                                </li>
                            ))}
                        </ul>
                        <button onClick={() => handleDelete(client._id)}>Delete User</button>
                    </li>
                ))}
            </ul>
            <button onClick={() => navigate('/registerClient')}>Register New User</button>
            <button onClick={() => navigate('/poManagement')}>Back</button>
        </div>
    );
}

export default Clients;
