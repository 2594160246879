// AddClient.js

import React, { useState, useEffect } from 'react';
import apiConnection from '../objects/ApiConnection';  
import { useNavigate } from 'react-router-dom';

function AddClient() {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [projects, setProjects] = useState([]);
    const [selectedProject, setSelectedProject] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchProjects = async () => {
            try {
                const response = await apiConnection.getMyProjects();
                setProjects(response.projects);
                if (response.projects.length > 0) {
                    setSelectedProject(response.projects[0].id);
                }
            } catch (error) {
                console.error('Error fetching projects:', error);
            }
        };

        fetchProjects();
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();  
        const clientData = { name, description, project: selectedProject };

        try {
            const response = await apiConnection.addOrganization(clientData);
            alert('Client added successfully!');
            setName('');
            setDescription('');
            navigate('/clientdatamodel', { state: { projectId: selectedProject, clientId: response.id  } })
        } catch (error) {
            alert(`Failed to add client: ${error.message}`);
        }
    };

    const handleProjectChange = (event) => {
        setSelectedProject(event.target.value);
    };

    return (
        <div>
            <h1>Add New Client</h1>
            <form onSubmit={handleSubmit}>
                <div>
                    <label>
                        Client Name:
                        <input 
                            type="text" 
                            value={name} 
                            onChange={(e) => setName(e.target.value)} 
                            required 
                        />
                    </label>
                </div>
                <div>
                    <label>
                        Description:
                        <textarea 
                            value={description} 
                            onChange={(e) => setDescription(e.target.value)} 
                            required 
                        />
                    </label>
                </div>
                <div>
                    <label>
                        Select Project:
                        <select value={selectedProject} onChange={handleProjectChange}>
                            {projects.map(project => (
                                <option key={project.id} value={project.id}>
                                    {project.name}
                                </option>
                            ))}
                        </select>
                    </label>
                </div>
                <button type="submit">Add Client</button>
            </form>
        </div>
    );
}

export default AddClient;
