import React, { useState, useEffect } from 'react';
import API_CONFIG from '../config';
import apiConnection from '../objects/ApiConnection';
import { useNavigate, useLocation } from 'react-router-dom';

function UploadClientDataModel() {
    
    const [dataModel, setDataModel] = useState('');
    const [message, setMessage] = useState('');
    const location = useLocation();
    const navigate = useNavigate();

    // get the correct settings
    const [client, setClient] = useState({});
    const [selectedProject, setSelectedProject] = useState('');
    const [dataModels, setDataModels] = useState([]);
    const [selectedDataModel, setSelectedDataModel] = useState('');

    // input
    const [keyTypes, setKeyTypes] = useState({});
    
    // utils
    const [usedKeyTypes, setUsedKeyTypes] = useState({});
    const [allKeys, setAllKeys] = useState({});

    // output
    const [title, setTitle] = useState('');
    const [projectDataModel, setProjectDataModel] = useState({});
    const [extraDataPoints, setExtraDataPoints] = useState([]);

    // utils extra data points:
    const [newKey, setNewKey] = useState('');
    const [newType, setNewType] = useState('String'); // Default type is String
    const [newSecurity, setNewSecurity] = useState('Hashed'); // Default security is Hashed
    const [newRequired, setNewRequired] = useState('Required'); // Default option is Required
    const [mapsToExtra, setMapsToExtra] = useState([]);
    const [mapsToValue, setMapsToValue] = useState(''); // For adding to the maps_to array
    const [uniqueAttributes, setUniqueAttributes] = useState([]);
    const [uniqueAttribute, setUniqueAttribute] = useState('');
    const [setAddress, setSetAddress] = useState('Value'); // Default to "Value"

    useEffect(() => {
        const projectId = location.state?.projectId; // Get the project ID from navigation state

        console.log("PROJECT ID")
        console.log(projectId)

        if (projectId) {
            apiConnection.fetchProjectById(projectId)
                .then(data => {
                    setSelectedProject(data); // Set the project data retrieved
                    console.log(data);

                    setSelectedDataModel('');
                    apiConnection.fetchDataModels(data._id)
                        .then(data => {
                            console.log(data);
                            setDataModels(data);
                        })
                        .catch(error => setMessage(`Error: ${error.message}`));
                })
                .catch(error => console.error(`Error fetching project with ID ${projectId}:`, error));
        }

        const clientId = location.state?.clientId;
        if (clientId) {
            try {
                const clientData = apiConnection.fetchClient(clientId).then(data => {
                    console.log(data);
                    setClient(data);
                });
            } catch (error) {
                console.error('Error fetching client:', error);
            }
        }

    }, [location.state]);

    function isValidDateFormat(dateString) {
        const dateFormat1 = /^\d{4}-\d{2}-\d{2}$/; // YYYY-MM-DD
        const dateFormat2 = /^\d{2}\/\d{2}\/\d{4}$/; // MM/DD/YYYY
        const dateFormat3 = /^\d{2}-\d{2}-\d{4}$/; // DD-MM-YYYY
        const dateFormat4 = /^\d{2}.\d{2}.\d{4}$/; // DD.MM.YYYY

        return dateFormat1.test(dateString) || dateFormat2.test(dateString) || dateFormat3.test(dateString) || dateFormat4.test(dateString);
    }

    useEffect(() => {
        if (selectedDataModel && selectedDataModel.id) {
            console.log(selectedDataModel.id);
            console.log(selectedProject);
            console.log(selectedProject._id);

            apiConnection.fetchProjectDataModel(selectedDataModel.id, selectedProject._id)
                .then(data => {
                    // Ensure that maps_to is always an array
                    const updatedAttributes = data.attributes.map(attr => ({
                        ...attr,
                        maps_to: Array.isArray(attr.maps_to) ? attr.maps_to : ['']
                    }));
                    setProjectDataModel({ ...data, attributes: updatedAttributes });
                })
                .catch(error => setMessage(`Error: ${error.message}`));
        }
    }, [selectedDataModel, selectedProject]);

    const getKeyTypes = () => {
        let types = {};
        const traverse = (obj, path = '') => {
            Object.keys(obj).forEach(key => {
                const fullPath = path ? `${path}.${key}` : key;
                const value = obj[key];
                let type;
                if (Array.isArray(value)) {
                    type = 'Array';
                    if (value.length > 0) {
                        const firstElement = value[0];
                        const firstElementType = Array.isArray(firstElement) ? 'Array' : typeof firstElement;
                        types[`${fullPath}.0`] = firstElementType;
                    }
                    traverse(value, fullPath);
                } else if (isValidDateFormat(value)) {
                    type = 'Date';
                } else if (typeof value === 'object' && value !== null) {
                    type = 'JSON';
                    traverse(value, fullPath);
                } else {
                    type = typeof value;
                    type = type.charAt(0).toUpperCase() + type.slice(1);
                }
                types[fullPath] = type;
            });
        };

        console.log(dataModel);

        const jsonDataModel = JSON.parse(dataModel);

        console.log(jsonDataModel);

        traverse(jsonDataModel);

        console.log(types);

        setKeyTypes({ ...types });
        setAllKeys({ ...types });

        return types;
    };

    const moveKeysAndChildren = (keyPrefix, oldKeyPrefix) => {
        console.log("Starting moveKeysAndChildren");

        // Prepare new states based on current states
        let updatedSource = { ...keyTypes };
        let updatedTarget = { ...usedKeyTypes };

        console.log(keyPrefix)
        console.log(oldKeyPrefix)

        // If there is a new key prefix to move from keyTypes to usedKeyTypes
        if (keyPrefix) {
            Object.keys(keyTypes).forEach(key => {
                if (key.startsWith(keyPrefix)) {
                    updatedTarget[key] = keyTypes[key];  // Move to usedKeyTypes
                    delete updatedSource[key];  // Remove from keyTypes
                }
            });
        }

        // If there is an old key prefix to move back from usedKeyTypes to keyTypes
        if (oldKeyPrefix != "") {
            Object.keys(usedKeyTypes).forEach(key => {
                if (key.startsWith(oldKeyPrefix)) {
                    updatedSource[key] = usedKeyTypes[key];  // Move to keyTypes
                    delete updatedTarget[key];  // Remove from usedKeyTypes
                }
            });
        }

        // Update the states with the new mappings
        setKeyTypes(updatedSource);
        setUsedKeyTypes(updatedTarget);

        console.log("Updated keyTypes:", updatedSource);
        console.log("Updated usedKeyTypes:", updatedTarget);
    };

    const handleInputChange = (event) => {
        setDataModel(event.target.value);
    };

    const handleProjectChange = (event) => {
        setSelectedProject(event.target.value);
    };

    const handleDataModelChange = (event) => {
        const modelId = event.target.value;
        const model = dataModels.find(model => model.id === modelId);
        setSelectedDataModel(model || {});
        if (model && model.id) {
            apiConnection.fetchProjectDataModel(model.id, selectedProject._id)
                .then(data => {
                    console.log(data);
                    // Ensure that maps_to is always an array
                    const updatedAttributes = data.attributes.map(attr => ({
                        ...attr,
                        maps_to: Array.isArray(attr.maps_to) ? attr.maps_to : []
                    }));
                    setProjectDataModel({ ...data, attributes: updatedAttributes });
                })
                .catch(error => setMessage(`Error: ${error.message}`));
        } else {
            setProjectDataModel({}); // Clear previous data model if no valid model is selected
        }
    };

    const handleMapChange = (index, newValue) => {
        // Create a new copy of attributes
        const updatedAttributes = projectDataModel.attributes.map((attr, idx) => {
            console.log("Maps Change?")
            if (idx === index) {
                const updatedMapsTo = [...attr.maps_to, newValue]; // Append new value to maps_to array
                moveKeysAndChildren(newValue, attr.maps_to);
                return { ...attr, maps_to: updatedMapsTo };
            }
            return attr;
        });

        const tempProjectDataModel = { ...projectDataModel, attributes: updatedAttributes };

        // Update the projectDataModel with the new attributes
        setProjectDataModel(tempProjectDataModel);
    };

    const handleAddMapsTo = (index) => {
        const updatedAttributes = projectDataModel.attributes.map((attr, idx) => {
            if (idx === index) {
                return { ...attr, maps_to: [...attr.maps_to, ''] };
            }
            return attr;
        });

        setProjectDataModel({ ...projectDataModel, attributes: updatedAttributes });
    };

    const handleMapChangeExtra = (newValue) => {
        console.log("maps to extra")
        if (newValue.trim() && !mapsToExtra.includes(newValue)) {
            setMapsToExtra([...mapsToExtra, newValue]);
            setMapsToValue('');
        }
    };

    const handleExtraAttributeMapChange = (attributeIndex, mapsToIndex, newValue) => {
        // Get the attribute to update
        const updatedAttributes = [...extraDataPoints];
        const attribute = updatedAttributes[attributeIndex];

        // Store old maps_to value before updating
        const oldMapsToValue = attribute.maps_to[mapsToIndex];

        // Update the maps_to array with the new value
        attribute.maps_to[mapsToIndex] = newValue;

        // Move keys and children based on the new and old values
        moveKeysAndChildren(newValue, oldMapsToValue);

        // Set the updated extraDataPoints state
        setExtraDataPoints(updatedAttributes);
    };

    const handleAddMapsToExtra = () => {
        setMapsToExtra([...mapsToExtra, '']);
    };

    const handleDelete = async index => {
        // Filter out the attribute at the given index
        console.log(extraDataPoints[index].maps_to);

        moveKeysAndChildren('', extraDataPoints[index].maps_to);
        const updatedAttributes = extraDataPoints.filter((_, idx) => idx !== index);
        setExtraDataPoints([...updatedAttributes]);
    };

    const handleAddAttribute = () => {

        moveKeysAndChildren(mapsToValue, '')
        mapsToExtra.forEach((mapTo) => moveKeysAndChildren(mapTo, ''));

        setExtraDataPoints([
            ...extraDataPoints, 
            { key: newKey, type: newType, security: newSecurity, required: newRequired, maps_to: mapsToExtra, address:setAddress }
        ]);

        setNewKey('');
        setNewType('String');
        setNewSecurity('Hashed');
        setNewRequired('Required');
        setMapsToExtra([]);
        setSetAddress('Value');
    };


    const handleInputChangeExtraData = (setter) => (event) => {
        setter(event.target.value);
    };

    const handleChooseAttribute = (newAttr) => {
        // Check if the attribute is already added
        if (uniqueAttributes.some(attr => attr.key === newAttr)) {
            setMessage('This attribute has already been added.');
            return;
        }
        // Add the selected attribute to uniqueAttributes
        const newAttribute = {
            key: newAttr,
        };
        setUniqueAttributes([...uniqueAttributes, newAttribute]);
    };

    const handleDeleteUniqueAttribute = (index) => {
        // Create a new array excluding the attribute at the specified index
        const updatedAttributes = uniqueAttributes.filter((_, idx) => idx !== index);
        // Update the uniqueAttributes state with the new array
        setUniqueAttributes(updatedAttributes);
    };

    const checkDataModel = () => {
        try {
            getKeyTypes();
            setMessage('Valid JSON!');
        } catch (error) {
            setMessage(`Invalid JSON: ${error.message}`);
            setKeyTypes([]);
        }
    };

    const uploadDataModel = async () => {
        if (!title || !selectedProject || uniqueAttributes.length === 0 || JSON.stringify(keyTypes) !== '{}') {
            setMessage('Please fill out all required fields: client name, title, and project, and parse all fields');
            return;
        }
        console.log(projectDataModel.attributes);

        // Construct the data model object from the current state
        const dataToUpload = {
            title: title,
            attributes: projectDataModel.attributes ? [...projectDataModel.attributes, ...extraDataPoints] : [...extraDataPoints],
            unique: [...uniqueAttributes]
        };

        try {
            const response = await apiConnection.uploadClientDataModel(location.state?.clientId, dataToUpload);
            setMessage('Data Model successfully uploaded!');
            console.log('Upload response:', response);
            navigate('/POManagement');
        } catch (error) {
            setMessage('Failed to upload Data Model: ' + error.message);
            console.error('Upload error:', error);
        }
    };

    const handleSelectMapsToValue = (newValue) => {
        if (newValue.trim() && !mapsToExtra.includes(newValue)) {
            // Call moveKeysAndChildren if there’s any key management logic needed for the new value
            moveKeysAndChildren(newValue, mapsToValue);
        }
        
        // Update the mapsToValue state
        setMapsToValue(newValue);
    };

    return (
        <div>
            <h1>Upload Client Data Model</h1>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {/* Left side: Form controls and actions */}
                <div style={{ flex: '1', marginRight: '20px' }}>
                    <div>
                        <h2>{selectedProject.name}</h2>
                    </div>
                    <div>
                        <label>Select Data Model:</label>
                        <select value={selectedDataModel ? selectedDataModel.id : ''} onChange={handleDataModelChange}>
                            <option value="">{selectedDataModel ? selectedDataModel.title : "Select a Data Model"}</option>
                            {dataModels.map((model, index) => (
                                <option key={index} value={model.id}>{model.title}</option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <h2>{client.name}</h2>
                    </div>
                    <textarea
                        value={dataModel}
                        onChange={handleInputChange}
                        placeholder="Enter your data model here..."
                        rows="10"
                        cols="50"
                    />
                    <button onClick={checkDataModel}>Check Data Model</button>
                    {message && <p>{message}</p>}
                    {keyTypes && (
                        <div>
                            <h2>Key Types:</h2>
                            <ul>
                                {Object.entries(keyTypes).map(([key, type], index) => (
                                    <li key={index}>{key}: {type}</li>
                                ))}
                            </ul>
                        </div>
                    )}
                </div>

                {/* Right side: Displaying attributes and key types from the data model */}
                <div style={{ flex: '1', marginLeft: '20px' }}>
                    <div>
                        <input
                            type="text"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            placeholder="title"
                        />
                    </div>
                    <h2>Unique Value</h2>
                    {uniqueAttributes.length > 0 ? (
                        <ul>
                            {uniqueAttributes.map((attr, index) => (
                                <li key={index}>
                                    {attr.key}
                                    <button onClick={() => handleDeleteUniqueAttribute(index)}>Remove</button>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <p>No unique attributes chosen.</p>
                    )}
                    <select value={uniqueAttribute || ''} onChange={(e) => handleChooseAttribute(e.target.value)}>
                        <option value="">Select Unique Key</option>
                        {Object.entries(allKeys).map(([key, _], index) => (
                            <option key={index} value={key}>{key}</option>
                        ))}
                    </select>
                    <h2>Attributes</h2>
                    {projectDataModel.attributes ? (
                        <div>
                            {projectDataModel.attributes.map((attribute, index) => (
                                <div key={index}>
                                    <strong>Key:</strong> {attribute.key}, 
                                    <strong>Required:</strong> {attribute.required}, 
                                    <strong>Security:</strong> {attribute.security}, 
                                    <strong>Type:</strong> {attribute.type},
                                    <strong>Address:</strong> {attribute.address ? attribute.address : 'Value'}
                                    <ul>
                                        {attribute.maps_to.map((mapTo, idx) => (
                                            <li key={idx}>
                                                <select
                                                    value={mapTo}
                                                    onChange={(e) => {
                                                        const newValue = e.target.value;
                                                        const updatedMapsTo = [...attribute.maps_to];
                                                        updatedMapsTo[idx] = newValue;
                                                        handleMapChange(index, newValue); // Ensure handleMapChange is called
                                                        const updatedAttributes = [...projectDataModel.attributes];
                                                        updatedAttributes[index].maps_to = updatedMapsTo;
                                                        setProjectDataModel({ ...projectDataModel, attributes: updatedAttributes });
                                                    }}
                                                >
                                                    <option value="">{mapTo ? mapTo : "--Map To--"}</option>
                                                    {Object.entries(keyTypes).map(([key, typeObj], subIndex) => {
                                                        return typeObj === attribute.type ? (
                                                            <option key={subIndex} value={key}>{key}</option>
                                                        ) : null;
                                                    })}
                                                </select>
                                            </li>
                                        ))}
                                    </ul>
                                    <button onClick={() => handleAddMapsTo(index)}>Add Maps To</button>
                                </div>
                            ))}
                            <h2>Extra Attributes</h2>
                            {extraDataPoints.map((attribute, index) => (
                                <div>
                                    {extraDataPoints.map((attribute, index) => (
                                        <div key={index}>
                                            <strong>{attribute.key}:</strong> {attribute.type}, {attribute.security}, {attribute.required}, {attribute.address}
                                            <ul>
                                                {attribute.maps_to.map((mapTo, idx) => (
                                                    <li key={idx}>
                                                        <select
                                                            value={mapTo}
                                                            onChange={(e) => handleExtraAttributeMapChange(index, idx, e.target.value)}
                                                        >
                                                            <option value="">{mapTo ? mapTo : "--Map To--"}</option>
                                                            {Object.entries(keyTypes).map(([key, typeObj], subIndex) => (
                                                                typeObj === attribute.type ? (
                                                                    <option key={subIndex} value={key}>{key}</option>
                                                                ) : null
                                                            ))}
                                                        </select>
                                                    </li>
                                                ))}
                                            </ul>
                                            <button onClick={() => handleDelete(index)}>Delete</button>
                                        </div>
                                    ))}
                                </div>
                            ))}
                            <div>
                                <input type="text" placeholder="Attribute name" value={newKey} onChange={handleInputChangeExtraData(setNewKey)} />
                                <select value={newType} onChange={handleInputChangeExtraData(setNewType)}>
                                    <option value="String">String</option>
                                    <option value="JSON">JSON</option>
                                    <option value="Array">Array</option>
                                    <option value="Number">Number</option>
                                    <option value="Date">Date</option>
                                </select>
                                <select value={newSecurity} onChange={handleInputChangeExtraData(setNewSecurity)}>
                                    <option value="Cleartext">Cleartext</option>
                                    <option value="Hashed">Hashed</option>
                                </select>
                                <select value={newRequired} onChange={handleInputChangeExtraData(setNewRequired)}>
                                    <option value="Optional">Optional</option>
                                    <option value="Required">Required</option>
                                </select>
                                <select value={setAddress} onChange={(e) => setSetAddress(e.target.value)}>
                                    <option value="Value">Value</option>
                                    <option value="Address">Address</option>
                                </select>
                                <div>
                                    <select value={mapsToValue || ''} onChange={(e) => setMapsToValue(e.target.value)}>
                                        <option value="">{mapsToValue ? mapsToValue : "--Map To--"}</option>
                                        {Object.entries(keyTypes).map(([key, type], subIndex) => (
                                            <option key={subIndex} value={key}>{key}</option>
                                        ))}
                                    </select>
                                    <button onClick={() => handleMapChangeExtra(mapsToValue)}>Add Maps To</button>
                                </div>
                                <ul>
                                    {mapsToExtra.map((mapTo, index) => (
                                        <li key={index}>
                                            {mapTo}
                                            <button onClick={() => setMapsToExtra(mapsToExtra.filter((_, i) => i !== index))}>Remove</button>
                                        </li>
                                    ))}
                                </ul>
                                <button onClick={handleAddAttribute}>Add Attribute</button>
                            </div>
                            <button onClick={uploadDataModel}>Upload Data Model</button>
                        </div>
                    ) : (
                        <p>No attributes to display.</p>
                    )}
                </div>
            </div>
        </div>
    );
}

export default UploadClientDataModel;
