import React, { useState, useEffect } from 'react';
import apiConnection from '../objects/ApiConnection';
import { useLocation, useNavigate } from 'react-router-dom';

function AddEmailServer() {
    const [emailAddress, setEmailAddress] = useState('');
    const [password, setPassword] = useState('');
    const [imapServer, setImapServer] = useState('');
    const [whitelistFrom, setWhitelistFrom] = useState(['']);
    const [whitelistSubject, setWhitelistSubject] = useState(['']);
    const location = useLocation();
    const navigate = useNavigate();
    const clientId = location.state?.clientId;  // Get clientId from location state

    useEffect(() => {
        const fetchEmailServerConfig = async () => {
            try {
                const config = await apiConnection.getEmailServerConfig(clientId);

                if (config) {
                    setEmailAddress(config.email_address || '');
                    setPassword(config.password || '');  // Prefill the password
                    setImapServer(config.imap_server || '');
                    setWhitelistFrom(config.whitelist_from || ['']);
                    setWhitelistSubject(config.whitelist_subject || ['']);
                }
            } catch (error) {
                console.error('Error fetching email server config:', error);
            }
        };

        if (clientId) {
            fetchEmailServerConfig();
        }
    }, [clientId]);

    const handleAddWhitelistFrom = () => {
        setWhitelistFrom([...whitelistFrom, '']);
    };

    const handleRemoveWhitelistFrom = (index) => {
        const newWhitelistFrom = [...whitelistFrom];
        newWhitelistFrom.splice(index, 1);
        setWhitelistFrom(newWhitelistFrom);
    };

    const handleWhitelistFromChange = (index, value) => {
        const newWhitelistFrom = [...whitelistFrom];
        newWhitelistFrom[index] = value;
        setWhitelistFrom(newWhitelistFrom);
    };

    const handleAddWhitelistSubject = () => {
        setWhitelistSubject([...whitelistSubject, '']);
    };

    const handleRemoveWhitelistSubject = (index) => {
        const newWhitelistSubject = [...whitelistSubject];
        newWhitelistSubject.splice(index, 1);
        setWhitelistSubject(newWhitelistSubject);
    };

    const handleWhitelistSubjectChange = (index, value) => {
        const newWhitelistSubject = [...whitelistSubject];
        newWhitelistSubject[index] = value;
        setWhitelistSubject(newWhitelistSubject);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const emailServerData = {
            email_address: emailAddress,
            password,
            imap_server: imapServer,
            whitelist_from: whitelistFrom,
            whitelist_subject: whitelistSubject,
            client_id: clientId,
        };

        try {
            await apiConnection.addEmailServer(emailServerData);
            alert('Email server added successfully');
            // navigate('/');  // Redirect to another page after successful submission
        } catch (error) {
            console.error('Error adding email server:', error);
            alert(`Failed to add email server: ${error.message}`);
        }
    };

    return (
        <div>
            <h1>Add Email Server</h1>
            <form onSubmit={handleSubmit}>
                <div>
                    <label>Email Address:</label>
                    <input
                        type="email"
                        value={emailAddress}
                        onChange={(e) => setEmailAddress(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label>Password:</label>
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label>IMAP Server:</label>
                    <input
                        type="text"
                        value={imapServer}
                        onChange={(e) => setImapServer(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label>Whitelist From:</label>
                    {whitelistFrom.map((item, index) => (
                        <div key={index}>
                            <input
                                type="text"
                                value={item}
                                onChange={(e) => handleWhitelistFromChange(index, e.target.value)}
                                required
                            />
                            {index > 0 && (
                                <button type="button" onClick={() => handleRemoveWhitelistFrom(index)}>
                                    Remove
                                </button>
                            )}
                        </div>
                    ))}
                    <button type="button" onClick={handleAddWhitelistFrom}>Add More</button>
                </div>
                <div>
                    <label>Whitelist Subject:</label>
                    {whitelistSubject.map((item, index) => (
                        <div key={index}>
                            <input
                                type="text"
                                value={item}
                                onChange={(e) => handleWhitelistSubjectChange(index, e.target.value)}
                                required
                            />
                            {index > 0 && (
                                <button type="button" onClick={() => handleRemoveWhitelistSubject(index)}>
                                    Remove
                                </button>
                            )}
                        </div>
                    ))}
                    <button type="button" onClick={handleAddWhitelistSubject}>Add More</button>
                </div>
                <button type="submit">Add Email Server</button>
            </form>
        </div>
    );
}

export default AddEmailServer;
