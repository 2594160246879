import React, { useState, useEffect } from 'react';
import apiConnection from '../objects/ApiConnection';
import { useNavigate } from 'react-router-dom';

function POManagement() {
    const [projects, setProjects] = useState([]);
    const [clients, setClients] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchProjects = async () => {
            try {
                const response = await apiConnection.getMyProjects();
                setProjects(response.projects);
            } catch (error) {
                console.error('Error fetching projects:', error);
            }
        };

        const fetchClients = async () => {
            try {
                const response = await apiConnection.getMyOrganizations();
                setClients(response.clients);
            } catch (error) {
                console.error('Error fetching clients:', error);
            }
        };

        fetchProjects();
        fetchClients();
    }, []);

    const handleCreateNewProject = () => {
        navigate('/createNewProject');
    };

    const handleAddExistingProject = async () => {
        navigate('/AddExistingProject');
    };

    const handleAddClient = () => {
        navigate('/AddClient');
    };

    const handleAddExistingClient = () => {
        navigate('/AddExistingClient');
    };

    // Handler for navigating to the ClientDataModels screen with client ID
    const handleClientClick = (clientId) => {
        navigate('/clientDataModels', { state: { clientid: clientId } });
    };

    // New handler for navigating to the ProjectDataModels screen with project ID
    const handleProjectClick = (projectId) => {
        navigate('/ProjectDataModels', { state: { projectId: projectId } });
    };

    return (
        <div>
            <h1>Product Owner Management</h1>
            <p>Welcome to the Product Owner Management Dashboard. Here you can manage your projects and settings.</p>
            <h2>My Projects</h2>
            <ul>
                {projects.map(project => (
                    // Update each project to be a clickable list item
                    <li key={project.id} onClick={() => handleProjectClick(project.id)} style={{ cursor: 'pointer' }}>
                        {project.name}
                    </li>
                ))}
            </ul>
            <button onClick={handleCreateNewProject}>Create New Project</button>
            <button onClick={handleAddExistingProject}>Add Existing Project</button>
            <h2>My Clients</h2>
            <ul>
                {clients.map(client => (
                    // Update each client to be a clickable list item
                    <li key={client.id} onClick={() => handleClientClick(client.id)} style={{ cursor: 'pointer' }}>
                        {client.name}
                    </li>
                ))}
            </ul>
            <button onClick={handleAddClient}>Add Client</button>
            <button onClick={handleAddExistingClient}>Add Existing Client</button>
        </div>
    );
}

export default POManagement;
