import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, Navigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { jwtDecode } from 'jwt-decode';  // Corrected import
import HelloWorld from './screens/HelloWorld';
import UploadClientDataModel from './screens/UploadClientDataModel';
import RegisterUser from './screens/RegisterUser';
import LoginUser from './screens/LoginUser';
import LoginPO from './screens/LoginPO';
import POManagement from './screens/POManagement';
import CreateNewProject from './screens/CreateNewProject';
import AddExistingProject from './screens/AddExistingProject';
import AddClient from './screens/AddClient';
import AddExistingClient from './screens/AddExistingClient';
import ClientDataModels from './screens/ClientDataModels';
import ProjectDataModels from './screens/ProjectDataModels';
import AddImportAPI from './screens/AddImportAPI';
import AddEmailServer from './screens/AddEmailServer';
import Clients from './screens/Clients';
import RegisterClient from './screens/RegisterClient';
import ProtectedRoute from './components/ProtectedRoute';
import LoginWithToken from './screens/LoginWithToken';
import ManageAPIKeys from './screens/ManageAPIKeys';
import Documentation from './screens/Documentation';
import './css/App.css'; // Import the CSS file

function App() {
  const [cookies] = useCookies(['jwtToken']);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userRole, setUserRole] = useState('');
  const [userId, setUserId] = useState('');

  useEffect(() => {
    const token = cookies.jwtToken;
    if (token) {
      setIsAuthenticated(true);
      const decodedToken = jwtDecode(token);  // Decode the JWT token using jwtDecode
      setUserRole(decodedToken.role);  // Extract the role from the decoded token
      setUserId(decodedToken.user_id);  // Extract the user ID from the decoded token
      console.log(decodedToken.user_id)
    } else {
      setIsAuthenticated(false);
      setUserRole('');
      setUserId('');
    }
  }, [cookies.jwtToken]);

  return (
    <Router>
      <div className="App">
        <header className="top-bar">
          <div className="logo">
            <Link to="/">MyApp</Link>
          </div>
          <nav className="nav-links">
            <Link to="/">Home</Link>
            {isAuthenticated && userRole !== 'client' ? (
              <>
                <Link to="/pomanagement">PO Management</Link>
                <Link to="/createNewProject">Create New Project</Link>
                <Link to="/addClient">Add Client</Link>
                <Link to="/Clients">Clients</Link>
                <Link to="/loginPO">PO</Link>
              </>
            ) : (
              <>
                {!isAuthenticated && (
                  <>
                    <Link to="/register">Register</Link>
                    <Link to="/login">Login</Link>
                    <Link to="/loginPO">PO</Link>
                  </>
                )}
                {isAuthenticated && userRole === 'client' && (
                  <>
                    <Link to="/ClientDataModels">Dashboard</Link>
                    <Link to="/Clients">Users</Link>
                    <Link to="/login">Login</Link>
                    <Link to="/loginPO">PO</Link>
                  </>
                )}
              </>
            )}
          </nav>
        </header>
        <main>
          <Routes>
            {isAuthenticated && userRole === 'client' ? (
              <>
                {/* Redirect all routes to ClientDataModels for client role, passing userId */}
                <Route path="/register" element={<RegisterUser />} />
                <Route path="/login" element={<LoginUser />} />
                <Route path="/loginPO" element={<LoginPO />} />
                <Route path="/" element={<p>This is the Home Page. Click the buttons above to navigate.</p>} />
                <Route path="*" element={<Navigate to="/ClientDataModels" />} />
                <Route path="/ClientDataModels" element={<ProtectedRoute element={ClientDataModels} />} />
                <Route path="/register" element={<RegisterUser />} />
                <Route path="/login" element={<LoginUser />} />
                <Route path="/helloworld" element={<ProtectedRoute element={HelloWorld} />} />
                <Route path="/AddEmailServer" element={<ProtectedRoute element={AddEmailServer} />} />
                <Route path="/AddImportAPI" element={<ProtectedRoute element={AddImportAPI} />} />
                <Route path="/clientdatamodel" element={<ProtectedRoute element={UploadClientDataModel} />} />
                <Route path="/ManageAPIKeys" element={<ProtectedRoute element={ManageAPIKeys} />} />
                <Route path="/Clients" element={<ProtectedRoute element={Clients} />} />
                <Route path="/RegisterClient" element={<ProtectedRoute element={RegisterClient} />} />
                <Route path="/documentation" element={<ProtectedRoute element={Documentation} />} />
              </>
            ) : (
              <>
                <Route path="/register" element={<RegisterUser />} />
                <Route path="/login" element={<LoginUser />} />
                <Route path="/loginPO" element={<LoginPO />} />
                <Route path="/login_with_token/:token" element={<LoginWithToken />} />
                <Route path="/" element={<p>This is the Home Page. Click the buttons above to navigate.</p>} />
                <Route path="/helloworld" element={<ProtectedRoute element={HelloWorld} />} />
                <Route path="/documentation" element={<ProtectedRoute element={Documentation} />} />
                <Route path="/clientdatamodel" element={<ProtectedRoute element={UploadClientDataModel} />} />
                <Route path="/pomanagement" element={<ProtectedRoute element={POManagement} />} />
                <Route path="/createNewProject" element={<ProtectedRoute element={CreateNewProject} />} />
                <Route path="/AddExistingProject" element={<ProtectedRoute element={AddExistingProject} />} />
                <Route path="/AddClient" element={<ProtectedRoute element={AddClient} />} />
                <Route path="/AddExistingClient" element={<ProtectedRoute element={AddExistingClient} />} />
                <Route path="/ClientDataModels" element={<ProtectedRoute element={ClientDataModels} />} />
                <Route path="/AddEmailServer" element={<ProtectedRoute element={AddEmailServer} />} />
                <Route path="/AddImportAPI" element={<ProtectedRoute element={AddImportAPI} />} />
                <Route path="/Clients" element={<ProtectedRoute element={Clients} />} />
                <Route path="/RegisterClient" element={<ProtectedRoute element={RegisterClient} />} />
                <Route path="/ProjectDataModels" element={<ProtectedRoute element={ProjectDataModels} />} />
                <Route path="/ManageAPIKeys" element={<ProtectedRoute element={ManageAPIKeys} />} />
              </>
            )}
          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;
