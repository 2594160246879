import React, { useState } from 'react';
import apiConnection from '../objects/ApiConnection'; // Ensure this path matches where apiConnection is defined

function LoginUser() {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleMagicLinkRequest = async (event) => {
        event.preventDefault();
        const userData = { email };

        try {
            const response = await apiConnection.requestMagicLink(userData);
            console.log(response)
            if (response.message) {
                setMessage('Magic link sent to your email');
            } else {
                setMessage(response.error || 'Request failed');
            }
        } catch (error) {
            setMessage('Network error: ' + error.message);
        }
    };

    return (
        <div>
            <h1>Login with Magic Link</h1>
            <form onSubmit={handleMagicLinkRequest}>
                <input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
                <button type="submit">Send Magic Link</button>
            </form>
            {message && <p>{message}</p>}
        </div>
    );
}

export default LoginUser;
