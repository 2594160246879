// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.documentation-container {
    max-width: 900px;
    margin: 0 auto;
    padding: 20px;
}

.doc-title {
    text-align: left;
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 20px;
}

.doc-subtitle {
    font-size: 22px;
    font-weight: bold;
    margin-top: 20px;
}

.doc-section {
    border: 1px solid #ddd;
    padding: 15px;
    margin-bottom: 20px;
    background: #f9f9f9;
}

.doc-url {
    color: #007bff;
}

.doc-attributes-table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
}

.doc-attributes-table th, .doc-attributes-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.doc-json-block {
    background: #f4f4f4;
    padding: 10px;
    border: 1px solid #ddd;
    overflow: auto;
    font-family: monospace;
    color: #333;
    line-height: 1.5;
}

.doc-json-block-left {
    text-align: left;
    white-space: pre-wrap; /* Ensures long JSON lines break correctly */
    word-wrap: break-word; /* Prevents horizontal overflow */
    padding: 10px;
    margin: 10px 0;
    font-family: 'Courier New', Courier, monospace;
    background-color: #f5f5f5;
    border: 1px solid #e1e1e1;
}
`, "",{"version":3,"sources":["webpack://./src/DocumentationStyles.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,cAAc;IACd,aAAa;AACjB;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,sBAAsB;IACtB,aAAa;IACb,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,WAAW;IACX,yBAAyB;IACzB,cAAc;AAClB;;AAEA;IACI,sBAAsB;IACtB,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;IACnB,aAAa;IACb,sBAAsB;IACtB,cAAc;IACd,sBAAsB;IACtB,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,qBAAqB,EAAE,4CAA4C;IACnE,qBAAqB,EAAE,iCAAiC;IACxD,aAAa;IACb,cAAc;IACd,8CAA8C;IAC9C,yBAAyB;IACzB,yBAAyB;AAC7B","sourcesContent":[".documentation-container {\n    max-width: 900px;\n    margin: 0 auto;\n    padding: 20px;\n}\n\n.doc-title {\n    text-align: left;\n    font-size: 28px;\n    font-weight: bold;\n    margin-bottom: 20px;\n}\n\n.doc-subtitle {\n    font-size: 22px;\n    font-weight: bold;\n    margin-top: 20px;\n}\n\n.doc-section {\n    border: 1px solid #ddd;\n    padding: 15px;\n    margin-bottom: 20px;\n    background: #f9f9f9;\n}\n\n.doc-url {\n    color: #007bff;\n}\n\n.doc-attributes-table {\n    width: 100%;\n    border-collapse: collapse;\n    margin: 20px 0;\n}\n\n.doc-attributes-table th, .doc-attributes-table td {\n    border: 1px solid #ddd;\n    padding: 8px;\n    text-align: left;\n}\n\n.doc-json-block {\n    background: #f4f4f4;\n    padding: 10px;\n    border: 1px solid #ddd;\n    overflow: auto;\n    font-family: monospace;\n    color: #333;\n    line-height: 1.5;\n}\n\n.doc-json-block-left {\n    text-align: left;\n    white-space: pre-wrap; /* Ensures long JSON lines break correctly */\n    word-wrap: break-word; /* Prevents horizontal overflow */\n    padding: 10px;\n    margin: 10px 0;\n    font-family: 'Courier New', Courier, monospace;\n    background-color: #f5f5f5;\n    border: 1px solid #e1e1e1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
