import React, { useState } from 'react';
import apiConnection from '../objects/ApiConnection';
import { useNavigate } from 'react-router-dom';



function CreateNewProject() {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');

    const navigate = useNavigate();


    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    const handleDescriptionChange = (event) => {
        setDescription(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            // Call the addProject function from the API connection object
            const res = await apiConnection.addProject({ name, description });
            console.log(res)

            if(!res.error){
                navigate('/helloworld', { state: { projectId: res.id } })
            }
   
        } catch (error) {
            console.error('Error adding project:', error);
            // Handle error as needed
        }
    };

    return (
        <div>
            <h1>Create New Project</h1>
            <form onSubmit={handleSubmit}>
                <div>
                    <label htmlFor="name">Name:</label>
                    <input
                        type="text"
                        id="name"
                        value={name}
                        onChange={handleNameChange}
                        required
                    />
                </div>
                <div>
                    <label htmlFor="description">Description:</label>
                    <textarea
                        id="description"
                        value={description}
                        onChange={handleDescriptionChange}
                        required
                    ></textarea>
                </div>
                <button type="submit">Create Project</button>
            </form>
        </div>
    );
}

export default CreateNewProject;
