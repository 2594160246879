import React, { useState, useEffect } from 'react';
import apiConnection from '../objects/ApiConnection';  // Adjust path as necessary

/*
flow: add some general data
-> select the project
*/

function AddExistingProject() {
    const [projects, setProjects] = useState([]);  // State to hold the list of projects
    const [selectedProject, setSelectedProject] = useState('');  // State to hold the selected project ID

    useEffect(() => {
        // Fetch all projects when the component mounts
        apiConnection.getAllProjects()
            .then(data => {

                console.log(data)

                setProjects(data.projects);
                if (data.length > 0) {
                   setSelectedProject((data.projects || data)[0].id);  // Automatically select the first project
                }
            })
            .catch(error => console.error('Failed to fetch projects:', error));
    }, []);

    const handleProjectChange = (event) => {

        console.log(event.target.value)

        setSelectedProject(event.target.value);  // Update the selected project when user selects a different option
    };

    const handleAddProject = async () => {
        if (!selectedProject) {
            alert('No project selected.');
            return;
        }
        try {
            console.log(selectedProject)

            const response = await apiConnection.addExistingProjectToPO(selectedProject);
            alert(`Project added successfully: ${response.message}`);
        } catch (error) {
            alert(`Failed to add project: ${error.message}`);
            console.error('Error adding project:', error);
        }
    };

    return (
        <div>
            <h1>Add Existing Project</h1>
            <select value={selectedProject} onChange={handleProjectChange}>
                {projects.map(project => (
                    <option key={project.id} value={project.id}>
                        {project.name}
                    </option>
                ))}
            </select>
            <button onClick={handleAddProject}>Add Project</button>
            
        </div>
    );
}

export default AddExistingProject;
