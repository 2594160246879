import React, { useState, useEffect } from 'react';
import apiConnection from '../objects/ApiConnection';
import { useLocation, useNavigate } from 'react-router-dom';
import API_CONFIG from '../config';

function ProjectDataModels() {
	const [dataModels, setDataModels] = useState([]);
	const [project, setProject] = useState({});
	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		const fetchDataModels = async () => {
			try {
				const projectId = location.state?.projectId;
				if (!projectId) {
					console.error('No project ID provided');
					return;
				}

				const response = await apiConnection.fetchProjectDataModels(projectId);
				setDataModels(response);
			} catch (error) {
				console.error('Error fetching data models:', error);
			}
		};

		const fetchProject = async () => {
			const projectId = location.state?.projectId;
			if (projectId) {
				try {
					const projectData = await apiConnection.fetchProjectById(projectId);
					setProject(projectData);
				} catch (error) {
					console.error('Error fetching project:', error);
				}
			}
		};

		fetchDataModels();
		fetchProject();
	}, [location.state]);

	const handleAddDataModel = () => {
		const projectId = location.state?.projectId;
		navigate('/helloworld', { state: { projectId } });
	};

	const handleHideDataModel = async (modelId) => {
		try {
			const projectId = location.state?.projectId;
			await apiConnection.hideProjectDataModel(projectId, modelId);
			alert('Data model hidden successfully');
			// Optionally, refresh the list of data models
			const updatedDataModels = await apiConnection.fetchProjectDataModels(projectId);
			setDataModels(updatedDataModels);
		} catch (error) {
			console.error('Error hiding data model:', error);
			alert(`Failed to hide data model: ${error.message}`);
		}
	};

	return (
		<div>
			<h1>Project Data Models</h1>
			<p>Welcome to the Project Data Models Management Dashboard. Here you can view the list of all data models for the specified project.</p>
			<h2>Data Models List</h2>
			<ul>
				{dataModels.map(model => (
					<li key={model.id}>
						{model.title}:  
						<button onClick={() => handleHideDataModel(model.id)}>Hide</button>
					</li>
				))}
			</ul>
			<button onClick={handleAddDataModel}>Add Data Model</button>
		</div>
	);
}

export default ProjectDataModels;
