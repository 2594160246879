// src/components/ProtectedRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';

const ProtectedRoute = ({ element: Element }) => {
  const [cookies] = useCookies(['jwtToken']);

  // Check the authentication status from the cookies
  const isAuthenticated = cookies.jwtToken !== undefined;

  if (!isAuthenticated) {
    // Redirect to the login page if the user is not authenticated
    return <Navigate to="/login" replace />;
  }

  // If the user is authenticated, render the element
  return <Element />;
};

export default ProtectedRoute;
