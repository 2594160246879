import React, { useState, useEffect } from 'react';
import apiConnection from '../objects/ApiConnection'; // Import your API connection object
import { useNavigate, useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie'; // Import useCookies

function ManageAPIKeys() {
    const [apiKeys, setApiKeys] = useState([]);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);
    const [displayedApiKey, setDisplayedApiKey] = useState(''); // New state for the displayed API key
    const [cookies] = useCookies(['jwtToken']); // Access the JWT token cookie
    const navigate = useNavigate();
    const location = useLocation(); // Get access to location for clientId

    const clientId = location.state?.clientId; // Get clientId from state

    useEffect(() => {
        const fetchAPIKeys = async () => {
            try {
                const keys = await apiConnection.getMyAPIKeys(clientId); // Pass clientId to the API call
                setApiKeys(keys.api_keys); // Assuming the API returns the keys in `api_keys`
                setLoading(false);
            } catch (error) {
                console.error('Error fetching API keys:', error);
                setError('Failed to fetch API keys');
                setLoading(false);
            }
        };

        fetchAPIKeys();
    }, [clientId, cookies.jwtToken]); // Re-fetch if the token or clientId changes

    const handleGoBack = () => {
        navigate('/'); // Navigate back to home or another page
    };

    const handleGetNewApiKey = async () => {
        const name = prompt('Enter a name for the new API key:');
        if (!name) {
            alert('API key name is required');
            return;
        }

        try {
            const newKeyResponse = await apiConnection.getNewApiKey(name, clientId); // Pass clientId to the API call
            alert(`New API Key created: ${newKeyResponse.api_key}`);

            // Re-fetch the API keys to show the new one
            const updatedKeys = await apiConnection.getMyAPIKeys(clientId); // Pass clientId to the API call
            setApiKeys(updatedKeys.api_keys);
        } catch (error) {
            console.error('Error creating new API key:', error);
            setError('Failed to create a new API key');
        }
    };

    const handleDeactivateKey = async (apiKeyHash) => {
        if (window.confirm('Are you sure you want to deactivate this API key?')) {
            try {
                await apiConnection.deactivateMyAPIKey(apiKeyHash, clientId); // Pass clientId to the API call
                alert('API Key deactivated successfully');

                // Re-fetch the API keys to reflect the change
                const updatedKeys = await apiConnection.getMyAPIKeys(clientId); // Pass clientId to the API call
                setApiKeys(updatedKeys.api_keys);
            } catch (error) {
                console.error('Error deactivating API key:', error);
                setError('Failed to deactivate API key');
            }
        }
    };

    const handleGetAPIKey = async (apiKeyHash) => {
        try {
            const apiKeyResponse = await apiConnection.getAPIKey(apiKeyHash, clientId); // Pass clientId to the API call
            setDisplayedApiKey(apiKeyResponse.api_key); // Set the API key to the state for display
        } catch (error) {
            console.error('Error retrieving API key:', error);
            setError('Failed to retrieve API key');
        }
    };

    const handleCopyToClipboard = () => {
        navigator.clipboard.writeText(displayedApiKey)
            .then(() => alert('API Key copied to clipboard'))
            .catch(err => console.error('Failed to copy API key:', err));
    };

    return (
        <div>
            <h1>Manage API Keys</h1>
            {loading && <p>Loading API Keys...</p>}
            {error && <p style={{ color: 'red' }}>{error}</p>}

            {!loading && !error && apiKeys.length > 0 ? (
                <table>
                    <thead>
                        <tr>
                            <th>API Key Name</th>
                            <th>Creation Date</th>
                            <th>Actions</th> {/* New column for actions */}
                        </tr>
                    </thead>
                    <tbody>
                        {apiKeys.map((key, index) => (
                            <tr key={index}>
                                <td>{key.name}</td>
                                <td>{new Date(key.created_at).toLocaleString()}</td>
                                <td>
                                    <button onClick={() => handleDeactivateKey(key._id)}>Deactivate</button> {/* Deactivate button */}
                                    <button onClick={() => handleGetAPIKey(key._id)}>Get API Key</button> {/* Get API Key button */}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                !loading && <p>No API Keys found</p>
            )}

            {displayedApiKey && (
                <div>
                    <h3>Your API Key:</h3>
                    <p>{displayedApiKey}</p>
                    <button onClick={handleCopyToClipboard}>Copy API Key</button> {/* Button to copy the API key */}
                </div>
            )}

            <button onClick={handleGoBack}>Go Back</button>
            <button onClick={handleGetNewApiKey}>Get New API Key</button> {/* New button to get a new API key */}
        </div>
    );
}

export default ManageAPIKeys;
