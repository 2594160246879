import React, { useState } from 'react';
import apiConnection from '../objects/ApiConnection';  // Import the API connection utility
import { useLocation, useNavigate } from 'react-router-dom';


function AddImportAPI({ clientid, modelid }) {  // Assume clientid and modelid are passed as props
    const [inputValue, setInputValue] = useState(''); // State to hold the input value

    const location = useLocation();  // Get access to location object
    const navigate = useNavigate();  // Set up navigate function

    const handleInputChange = (event) => {
        setInputValue(event.target.value); // Update state with input value
    };

    const handleSubmit = async (event) => {
        event.preventDefault(); // Prevent the form from being submitted traditionally
        console.log("Submitted value:", inputValue); // Log or handle the submitted value

        // API call to submit the value, here we use a method from apiConnection that needs to be defined
        try {
            const response = await apiConnection.updateImportAPI(location.state?.clientid, location.state?.modelid, inputValue);
            console.log('API Response:', response);
            alert('API updated successfully!');
        } catch (error) {
            console.error('API call failed:', error);
            alert('Failed to update API.');
        }
    };

    return (
        <div style={{ margin: '20px' }}>
            <h1>Add Import API</h1>
            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    value={inputValue}
                    onChange={handleInputChange}
                    placeholder="Enter API details"
                    style={{ marginRight: '10px', padding: '8px' }}
                />
                <button type="submit" style={{ padding: '10px 15px' }}>
                    Submit
                </button>
            </form>
        </div>
    );
}

export default AddImportAPI;
