import React, { useState, useEffect } from 'react';
import apiConnection from '../objects/ApiConnection';
import { useLocation, useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';  // Corrected import
import { useCookies } from 'react-cookie'; // Import useCookies
import API_CONFIG from '../config';

function ClientDataModels() {
    const [dataModels, setDataModels] = useState([]);
    const [client, setClient] = useState({});
    const [userRole, setUserRole] = useState('');
    const location = useLocation();  // Get access to location object
    const navigate = useNavigate();  // Set up navigate function
    const [cookies] = useCookies(['jwtToken']); // Access the cookie

    useEffect(() => {
        // Decode token to get the user role
        const token = cookies.jwtToken;
        if (token) {
            const decodedToken = jwtDecode(token);  // Decode the JWT token using jwtDecode
            setUserRole(decodedToken.role);  // Extract the role from the decoded token
        }

        const fetchDataModels = async () => {
            let clientid = location.state?.clientid;

            // If clientid is not provided, get it from the cookie
            if (!clientid) {
                try {
                    const response = await apiConnection.getMyDataModels();
                    console.log(response)
                    setDataModels(response);
                } catch (error) {
                    console.error('Error fetching data models:', error);
                }
            } else {
                try {
                    const response = await apiConnection.fetchClientDataModels(clientid);
                    console.log(response)
                    setDataModels(response);
                } catch (error) {
                    console.error('Error fetching data models:', error);
                }
            }
        };

        fetchDataModels();

        const fetchClient = async () => {
            let clientid = location.state?.clientid;

            // If clientid is not provided, get it from the cookie
            if (!clientid) {
                try {
                    const clientData = await apiConnection.getMyOrganization();
                    console.log(clientData)
                    setClient(clientData);
                } catch (error) {
                    console.error('Error fetching client:', error);
                }
            } else {
                try {
                    const clientData = await apiConnection.fetchClient(clientid);
                    setClient(clientData);
                } catch (error) {
                    console.error('Error fetching client:', error);
                }
            }
        };

        fetchClient();
    }, [location.state, cookies.jwtToken]);

    const handleAddDataModel = () => {
        const projectId = client.project;  // You might need to adjust how you access projectId
        var clientId = location.state?.clientid;

        if (!clientId) {
            clientId = client._id;
        }

        navigate('/clientdatamodel', { state: { projectId, clientId } });
    };

    const handleNavigateToAddImportAPI = (modelid) => {
        navigate('/AddImportAPI', { state: { modelid: modelid, clientid: location.state?.clientid } });
    };

    const handleRunTestBatch = async (modelid) => {
        try {
            const result = await apiConnection.runTestBatch(location.state.clientid, modelid);
            console.log('Test Batch Result:', result);
            alert(`Test Batch executed successfully. ${result}`);
        } catch (error) {
            console.error('Error running test batch:', error);
            alert(`Failed to run test batch: ${error.message}`);
        }
    };

    const handleCopyToClipboard = (modelid) => {
        var clientId = location.state?.clientid;

        if (!clientId) {
            clientId = client._id;
        }

        const url = `${apiConnection.baseURL}${API_CONFIG.paths.parse_data}${clientId}/${modelid}`;
        navigator.clipboard.writeText(url).then(() => {
            alert('URL copied to clipboard');
        }).catch(err => {
            console.error('Failed to copy URL: ', err);
            alert('Failed to copy URL');
        });
    };

    const handleHideDataModel = async (modelid) => {
        try {
            const clientid = location.state?.clientid;
            await apiConnection.hideClientDataModel(clientid, modelid);
            alert('Data model hidden successfully');
            // Optionally, refresh the list of data models
            const updatedDataModels = await apiConnection.fetchClientDataModels(clientid);
            setDataModels(updatedDataModels);
        } catch (error) {
            console.error('Error hiding data model:', error);
            alert(`Failed to hide data model: ${error.message}`);
        }
    };

    const handleAddEmailServer = () => {
        const clientId = location.state?.clientid;
        navigate('/AddEmailServer', { state: { clientId } });
    };

    const handleAddMapping = async (modelid) => {
        const subject = prompt("Enter the subject for the mapping:");
        if (!subject) {
            alert("Subject is required to add a mapping.");
            return;
        }

        try {
            const clientid = location.state?.clientid;
            await apiConnection.addMapping(modelid, clientid, subject);
            alert('Mapping added successfully');
        } catch (error) {
            console.error('Error adding mapping:', error);
            alert(`Failed to add mapping: ${error.message}`);
        }
    };

    const handleCheckEmail = async () => {
        try {
            const clientid = location.state?.clientid;
            const emailData = await apiConnection.checkEmail(clientid);
            console.log('Email data fetched successfully:', emailData);
            alert('Email data fetched successfully');
        } catch (error) {
            console.error('Error checking email:', error);
            alert(`Failed to check email: ${error.message}`);
        }
    };

    const handleManageAPIKeys = () => {
        var clientId = location.state?.clientid;

        if (!clientId) {
            clientId = client._id;
        }

        navigate('/manageAPIKeys', { state: { clientId } }); // Navigate to ManageAPIKeys page
    };

    const handleDocumentation = () => {
        var clientId = location.state?.clientid;

        if (!clientId) {
            clientId = client._id;
        }

        navigate('/documentation', { state: { clientId } }); // Navigate to Documentation page
    };

    return (
        <div>
            <h1>Client Data Models</h1>
            <p>Welcome to the Client Data Models Management Dashboard. Here you can view the list of all data models for the specified client.</p>
            <h2>Data Models List</h2>
            <ul>
                {dataModels.map(model => (
                    <li key={model._id}>
                        {model.title}:  
                        <button onClick={() => handleCopyToClipboard(model._id)}>Copy Endpoint URL</button>
                        {userRole !== 'client' && (  // Only show this button if the role is NOT 'client'
                            <button onClick={() => handleNavigateToAddImportAPI(model._id)}>Add Import API</button>
                        )}
                        <button onClick={() => handleRunTestBatch(model._id)}>Run Test Batch</button>
                        <button onClick={() => handleHideDataModel(model._id)}>Hide Data Model</button>
                        <button onClick={() => handleAddMapping(model._id)}>Add Mapping</button>
                    </li>
                ))}
            </ul>
            <button onClick={handleAddDataModel}>Add Data Model</button>
            <button onClick={handleAddEmailServer}>Add Email Server</button>
            <button onClick={handleCheckEmail}>Check Email</button>
            <button onClick={handleManageAPIKeys}>Manage API Keys</button> {/* New button to manage API keys */}
            <button onClick={handleDocumentation}>Documentation</button> {/* New button to navigate to Documentation */}
        </div>
    );
}

export default ClientDataModels;
