import React, { useState, useEffect } from 'react';
import apiConnection from '../objects/ApiConnection';
import { useNavigate } from 'react-router-dom';

function AddExistingClient() {
    const [clients, setClients] = useState([]);
    const [selectedClient, setSelectedClient] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchClients = async () => {
            try {
                const response = await apiConnection.getAllClients();
                
                console.log(response)

                setClients(response);
            } catch (error) {
                console.error('Error fetching clients:', error);
            }
        };

        fetchClients();
    }, []);

    const handleAddClient = async () => {
        if (!selectedClient) {
            alert('No client selected.');
            return;
        }
        try {
            await apiConnection.addExistingClientToPO(selectedClient);
            alert('Client added successfully!');
            navigate('/POManagement'); // Redirect to POManagement after adding the client
        } catch (error) {
            alert(`Failed to add client: ${error.message}`);
        }
    };

    return (
        <div>
            <h1>Add Existing Client</h1>
            <label>
                Select Client:
                <select value={selectedClient} onChange={(e) => setSelectedClient(e.target.value)}>
                    <option value="">-- Select a client --</option>
                    {clients.map(client => (
                        <option key={client.id} value={client.id}>{client.name}</option>
                    ))}
                </select>
            </label>
            <button onClick={handleAddClient}>Add Client</button>
        </div>
    );
}

export default AddExistingClient;
