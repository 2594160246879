// src/screens/LoginPO.js
import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import { useLocation, useNavigate } from 'react-router-dom';
import apiConnection from '../objects/ApiConnection'; // Ensure this path matches where apiConnection is defined

function LoginPO() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');

    const [cookies, setCookie] = useCookies(['jwtToken']);  // Initialize cookies
    const location = useLocation();  // Get access to location object
    const navigate = useNavigate();  // Set up navigate function

    const handleLogin = async (event) => {
        event.preventDefault();
        const userData = { email, password };

        try {
            const response = await apiConnection.login(userData);
            if (response.token) {
                setMessage('Login successful');

                // Save the JWT token in a cookie
                setCookie('jwtToken', response.token, { path: '/' });

                navigate('/POManagement');
            } else {
                setMessage(response.error || 'Login failed');
            }
        } catch (error) {
            setMessage('Network error: ' + error.message);
        }
    };

    return (
        <div>
            <h1>Login</h1>
            <form onSubmit={handleLogin}>
                <input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
                <input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                />
                <button type="submit">Login</button>
            </form>
            {message && <p>{message}</p>}
        </div>
    );
}

export default LoginPO;
